import React, { useState, useEffect, useCallback } from "react";
import { useWebSocket } from "../hooks/useWebSocket";
import { GameResults } from "./GameResults";
import ReactCanvasPaint from "react-canvas-paint";
import "react-canvas-paint/dist/index.css";

interface GameProps {
  username: string;
  roomPassword: string;
  onGameExpired: () => void;
}

interface BookletEntry {
  content: string;
  author: string;
}

interface Booklet {
  [player: string]: BookletEntry[];
}

export const Game: React.FC<GameProps> = ({
  username,
  roomPassword,
  onGameExpired,
}) => {
  const [players, setPlayers] = useState<string[]>([]);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [gamePhase, setGamePhase] = useState<
    "waiting" | "playing" | "finished"
  >("waiting");
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [word, setWord] = useState<string>("");
  const [guess, setGuess] = useState<string>("");
  const [instruction, setInstruction] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [currentAction, setCurrentAction] = useState<"draw" | "guess">("draw");
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const [booklets, setBooklets] = useState<Booklet | null>(null);
  const [strokeWidth, setStrokeWidth] = useState<number>(5);
  const [canvasData, setCanvasData] = useState<ImageData | null>(null);
  const [canvasKey, setCanvasKey] = useState(0);
  const [copyFeedback, setCopyFeedback] = useState("");

  const { isConnected, messages, sendMessage } = useWebSocket(
    `wss://service.teleillustrations.com/ws/${roomPassword}/${username}`
  );

  const canvasWidth = Math.min(window.innerWidth - 40, 600);
  const canvasHeight = Math.min(window.innerWidth - 40, 400);

  useEffect(() => {
    if (isConnected) {
      sendMessage({ type: "get_game_state" });
    }
  }, [isConnected, sendMessage]);

  useEffect(() => {
    messages.forEach((message: any) => {
      switch (message.type) {
        case "game_state":
          setPlayers(message.state.players);
          setIsHost(message.state.host === username);
          setGamePhase(message.state.phase);
          setCurrentRound(message.state.current_round);
          break;
        case "player_joined":
          setPlayers((prevPlayers) => {
            if (!prevPlayers.includes(message.username)) {
              return [...prevPlayers, message.username];
            }
            return prevPlayers;
          });
          break;
        case "player_left":
          setPlayers((prevPlayers) =>
            prevPlayers.filter((player) => player !== message.username)
          );
          break;
        case "game_started":
          setGamePhase("playing");
          setWord(message.word);
          setInstruction(message.instruction);
          setCurrentAction(message.action);
          setContent(message.word);
          setIsWaiting(false);
          break;
        case "next_round":
          setCurrentRound(message.round);
          setCurrentAction(message.action);
          setContent(message.content);
          setInstruction(message.instruction);
          setGuess("");
          setIsWaiting(false);
          setCanvasData(null);
          break;
        case "game_finished":
          setGamePhase("finished");
          setBooklets(message.booklets);
          break;
        case "game_expired":
          onGameExpired();
          break;
        case "error":
          setError(message.message);
          setTimeout(() => setError(""), 5000);
          break;
      }
    });
  }, [messages, username, onGameExpired]);

  const startGame = () => {
    sendMessage({ type: "start_game" });
  };

  const handleClearCanvas = useCallback(() => {
    setCanvasData(null);
    setCanvasKey((prevKey) => prevKey + 1);
  }, []);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(roomPassword).then(
      () => {
        setCopyFeedback("Copied!");
        setTimeout(() => setCopyFeedback(""), 2000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
        setCopyFeedback("Failed to copy");
        setTimeout(() => setCopyFeedback(""), 2000);
      }
    );
  }, [roomPassword]);

  const submitTurn = async () => {
    let contentToSubmit: string;

    if (currentAction === "draw" && canvasData) {
      const canvas = document.createElement("canvas");
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.putImageData(canvasData, 0, 0);
        contentToSubmit = canvas.toDataURL();
      } else {
        console.error("Failed to get canvas context");
        return;
      }
    } else if (currentAction === "guess") {
      contentToSubmit = guess;
    } else {
      console.error("Invalid action or missing canvas data");
      return;
    }

    setIsWaiting(true);

    try {
      const response = await fetch(
        "https://service.teleillustrations.com/submit_turn",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: roomPassword,
            username: username,
            content: contentToSubmit,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        setError("Failed to submit turn");
        setIsWaiting(false);
      }
    } catch (err) {
      setError(
        "Error submitting turn: " +
          (err instanceof Error ? err.message : String(err))
      );
      setIsWaiting(false);
    }
  };

  if (!isConnected) {
    return <div>Connecting to game... Please wait.</div>;
  }

  return (
    <div className="game">
      <h1>TeleIllustrations</h1>
      <div className="room-password">
        <h2>Room Password: {roomPassword}</h2>
        <button onClick={copyToClipboard} className="secondary-button">
          {copyFeedback || "Copy"}
        </button>
      </div>
      <p>
        Players ({players.length}/8): {players.join(", ")}
      </p>
      {error && <p className="error">{error}</p>}
      {gamePhase === "waiting" ? (
        <div>
          {isHost ? (
            <button
              onClick={startGame}
              disabled={players.length < 4 || players.length > 8}
              className="primary-button"
            >
              Start Game
            </button>
          ) : (
            <p>Waiting for room host to start the game...</p>
          )}
          {players.length < 4 && (
            <p>Waiting for more players. Minimum 4 players required.</p>
          )}
          {players.length > 8 && (
            <p>Too many players. Maximum 8 players allowed.</p>
          )}
        </div>
      ) : gamePhase === "playing" ? (
        <div>
          <p className="current-round">Current Round: {currentRound + 1}</p>
          <p className="instruction">{instruction}</p>
          {isWaiting ? (
            <p>Waiting for other players to finish...</p>
          ) : (
            <>
              {currentAction === "draw" ? (
                <div className="canvas-container">
                  <p className="word-to-draw">{content}</p>
                  <ReactCanvasPaint
                    key={canvasKey}
                    width={canvasWidth}
                    height={canvasHeight}
                    onDraw={(imageData: ImageData) => setCanvasData(imageData)}
                    strokeWidth={strokeWidth}
                    data={canvasData || undefined}
                  />
                  <div className="controls">
                    <input
                      type="range"
                      min="1"
                      max="20"
                      value={strokeWidth}
                      onChange={(e) => setStrokeWidth(Number(e.target.value))}
                      className="input-range"
                    />
                    <span>Brush Size: {strokeWidth}</span>
                  </div>
                  <button
                    onClick={handleClearCanvas}
                    className="secondary-button"
                  >
                    Clear Canvas
                  </button>
                </div>
              ) : (
                <div className="canvas-container">
                  <img
                    src={content}
                    alt="Drawing to guess"
                    style={{ maxWidth: "100%", maxHeight: `${canvasHeight}px` }}
                  />
                  <input
                    type="text"
                    placeholder="Enter your guess"
                    value={guess}
                    onChange={(e) => setGuess(e.target.value)}
                    className="input-text"
                  />
                </div>
              )}
              <div className="canvas-container">
                <button
                  onClick={submitTurn}
                  disabled={isWaiting}
                  className="primary-button"
                >
                  Submit {currentAction === "draw" ? "Drawing" : "Guess"}
                </button>
              </div>
            </>
          )}
        </div>
      ) : gamePhase === "finished" ? (
        booklets ? (
          <GameResults booklets={booklets} />
        ) : (
          <p>Loading results...</p>
        )
      ) : (
        <p>Unknown game phase. Please refresh the page.</p>
      )}
    </div>
  );
};
