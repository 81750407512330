import React from "react";

interface HomeProps {
  onNewGame: () => void;
  onJoinGame: () => void;
  onShowRules: () => void;
}

export const Home: React.FC<HomeProps> = ({
  onNewGame,
  onJoinGame,
  onShowRules,
}) => {
  return (
    <div className="home">
      <h1>TeleIllustrations</h1>
      <div className="sub-title">
        <p>A free online game similar to Telestrations.</p>
      </div>
      <button onClick={onNewGame} className="primary-button">
        New Game
      </button>
      <button onClick={onJoinGame} className="primary-button">
        Join Game
      </button>
      <button onClick={onShowRules} className="secondary-button">
        Rules
      </button>
    </div>
  );
};
