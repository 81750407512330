import React, { useState } from "react";

interface LoginProps {
  setUsername: (username: string) => void;
  buttonText: string;
}

export const Login: React.FC<LoginProps> = ({ setUsername, buttonText }) => {
  const [name, setName] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUsername(name);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter your username"
        required
      />
      <button type="submit" className="primary-button">
        {buttonText}
      </button>
    </form>
  );
};
