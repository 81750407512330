import React, { useState } from "react";
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { Game } from "./components/Game";
import { Rules } from "./components/Rules";
import "./App.css";

const App: React.FC = () => {
  const [screen, setScreen] = useState<
    "home" | "newGame" | "joinGame" | "game"
  >("home");
  const [username, setUsername] = useState<string>("");
  const [roomPassword, setRoomPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showRules, setShowRules] = useState<boolean>(false);

  const navigateTo = (screenName: "home" | "newGame" | "joinGame" | "game") => {
    setScreen(screenName);
    setError("");
  };

  const handleCreateRoom = async (name: string) => {
    try {
      const response = await fetch(
        "https://service.teleillustrations.com/create_room",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: name }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.password) {
        setUsername(name);
        setRoomPassword(data.password);
        navigateTo("game");
      } else {
        throw new Error("Failed to create room");
      }
    } catch (err) {
      console.error("Error creating room:", err);
      setError("Failed to create room. Please try again.");
    }
  };

  const handleJoinRoom = async () => {
    if (!username || !roomPassword) {
      setError("Please enter both username and room password.");
      return;
    }

    try {
      const response = await fetch(
        "https://service.teleillustrations.com/join_room",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: roomPassword,
            username: username,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        navigateTo("game");
      } else {
        throw new Error("Failed to join room");
      }
    } catch (err) {
      console.error("Error joining room:", err);
      setError(
        "Failed to join room. Please check the room password and try again."
      );
    }
  };

  const handleGameExpired = () => {
    setError("Your game has expired. Please start a new one.");
    navigateTo("home");
  };

  return (
    <div className="App">
      {screen === "home" && (
        <Home
          onNewGame={() => navigateTo("newGame")}
          onJoinGame={() => navigateTo("joinGame")}
          onShowRules={() => setShowRules(true)}
        />
      )}
      {screen === "newGame" && (
        <div className="new-game">
          <h1>TeleIllustrations</h1>
          <Login setUsername={handleCreateRoom} buttonText="Create Game" />
          <button
            onClick={() => navigateTo("home")}
            className="secondary-button"
          >
            Back
          </button>
        </div>
      )}
      {screen === "joinGame" && (
        <div className="join-game">
          <h1>TeleIllustrations</h1>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="text"
            placeholder="Room Password"
            value={roomPassword}
            onChange={(e) => setRoomPassword(e.target.value)}
          />
          <div className="button-group">
            <button
              onClick={() => navigateTo("home")}
              className="secondary-button"
            >
              Back
            </button>
            <button onClick={handleJoinRoom} className="primary-button">
              Join Game
            </button>
          </div>
        </div>
      )}
      {screen === "game" && (
        <Game
          username={username}
          roomPassword={roomPassword}
          onGameExpired={handleGameExpired}
        />
      )}
      {error && <p className="error">{error}</p>}
      {showRules && <Rules onClose={() => setShowRules(false)} />}
      <footer>
        <p>
          Enjoying the game? Buy me a coffee:{" "}
          <a href="https://ko-fi.com/nefariousdream/">Ko-fi</a>
        </p>
      </footer>
    </div>
  );
};

export default App;
