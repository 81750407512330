// src/components/GameResults.tsx
import React from "react";

interface BookletEntry {
  content: string;
  author: string;
}

interface Booklet {
  [player: string]: BookletEntry[];
}

interface GameResultsProps {
  booklets: Booklet;
}

export const GameResults: React.FC<GameResultsProps> = ({ booklets }) => {
  const isBase64Image = (str: string) => {
    return str.startsWith("data:image");
  };

  return (
    <div className="game-results">
      <h2>Game Results</h2>
      {Object.entries(booklets).map(([player, entries]) => (
        <div key={player} className="player-booklet">
          <h3>{player}'s Booklet</h3>
          <div className="booklet-pages">
            {entries.map((entry, index) => (
              <div key={index} className="booklet-page">
                <h4>Page {index}</h4>
                <p>By: {entry.author}</p>
                {isBase64Image(entry.content) ? (
                  <div>
                    <p>Drawing:</p>
                    <img
                      src={entry.content}
                      alt={`Drawing for page ${index}`}
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </div>
                ) : (
                  <div>
                    <p>Guess:</p>
                    <p className="guess-text">{entry.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
