import React from "react";

export const Rules: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="rules-modal">
      <h2>Rules</h2>
      <p>
        In TeleIllustrations, players alternate between drawing and guessing in
        a fun game of miscommunication. Each player starts with a sketchbook and
        a word card.
      </p>
      <p>
        In the first turn, players write their word, then draw it. They then
        pass their sketchbook to the next player. The next player writes a guess
        based on the drawing.
      </p>
      <p>
        This process continues, with players alternating between drawing and
        guessing. At the end, everyone reveals the transformations from the
        original word to the final guess. Enjoy the laughter as you see how far
        the initial word has strayed!
      </p>
      <button onClick={onClose} className="primary-button">
        OK
      </button>
    </div>
  );
};
